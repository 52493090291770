import { useState } from 'react';
import styled from 'styled-components';
import { Trans } from '@lingui/react';

import ButtonSm from './ui/ButtonSm';
import Legal from './Legal';
import Confidentiality from './Confidentiality';
import { Modal } from 'antd';
import { classic } from '~/themes';
import ButtonLink from './ui/ButtonLink';
import { useNavigate } from 'react-router-dom';

function Footer() {
  const navigate = useNavigate();

  const [dialogOpen, setOpenDialog] = useState<
    'legal' | 'confidentiality' | undefined
  >(undefined);
  return (
    <Container>
      <ButtonLink onClick={() => navigate('/rgpd')}>
        <ButtonSm stylebold textcolor="primary">
          RGPD
        </ButtonSm>
      </ButtonLink>
      <ButtonSm
        stylebold
        textcolor="primary"
        onClick={() => setOpenDialog('legal')}
      >
        <Trans id="common.legals" />
      </ButtonSm>
      <ButtonSm
        stylebold
        textcolor="primary"
        onClick={() => setOpenDialog('confidentiality')}
      >
        <Trans id="common.confidentiality" />
      </ButtonSm>
      <a href="mailto:contact@reseau-e2c.fr">
        <ButtonSm stylebold textcolor="primary">
          <Trans id="common.support" />
        </ButtonSm>
      </a>
      <a href="https://reseau-e2c.fr/contact" target="_blank">
        <ButtonSm stylebold textcolor="primary">
          <Trans id="common.contact" />
        </ButtonSm>
      </a>

      <SModal
        onCancel={() => setOpenDialog(undefined)}
        open={Boolean(dialogOpen !== undefined)}
        bodyStyle={{ maxHeight: '90vh', overflow: 'scroll' }}
        width="auto"
        centered
        mask={true}
        footer={null}
      >
        {dialogOpen === 'legal' && (
          <Legal close={() => setOpenDialog(undefined)} />
        )}
        {dialogOpen === 'confidentiality' && (
          <Confidentiality close={() => setOpenDialog(undefined)} />
        )}
      </SModal>
    </Container>
  );
}
export default Footer;

const Container = styled.div`
  align-items: center;
  background-color: #270c21;
  bottom: 0;
  color: ${classic.token?.colorPrimary};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  min-height: 80px;
  width: 100%;
  z-index: 5;
  bottom: 0;
  left: 0;
  object-fit: cover;
  position: absolute;
  transform: translate3d(0, 0, 0);
  @media screen and (max-width: 767px) {
    min-height: 60px;
    position: static;
  }
`;

const SModal = styled(Modal)`
  & .ant-modal-content {
    padding: 0;
  }

  & .ant-modal-close {
    display: none;
  }
`;
