import { useLingui } from '@lingui/react';

import Title from './ui/Title';

import { ModalContainer, SDivClose } from '~/utils/styles';

type IProps = {
  close: () => void;
};

function Legal({ close }: IProps) {
  const { i18n } = useLingui();

  return (
    <ModalContainer>
      <Title withbackground fullBg="true" text={i18n._('common.legals')} />
      <h2>1. PLATEFORME HORIZONS.RESEAU-E2C.FR </h2>
      <p>
        La Plateforme Horizons : le jeu de ton avenir (ci-après la « Plateforme
        ») est éditée par L'Association « Réseau des Écoles de la 2e Chance en
        France » (ci-après « Réseau E2C France »).
      </p>
      <p>
        Association régie par la loi du 1er juillet 1901, immatriculée sous le
        numéro SIRET 478 692 924, dont le siège est sis 32, rue Benjamin
        Franklin, 51 009 CHALONS-EN-CHAMPAGNE, Représentée par M. Alexandre
        SCHAJER, en qualité de Président du Conseil d'Administration.
      </p>
      <p>Adresse de contact : contact@reseau-e2c.fr</p>
      <p>Téléphone : 03 26 69 69 70</p>

      <h2>2. CONCEPTION</h2>
      <p>
        La Plateforme a été conçue et développée par la société My-Serious-Game,
        immatriculée au RCS de TOURS, sous le numéro 804 673 754.{' '}
        <a href="https://my-serious-game.com" target="_blank">
          www.my-serious-game.com
        </a>{' '}
      </p>
      <h2>3. HÉBERGEMENT</h2>
      <p>La Plateforme est hébergée par [AWS].</p>
      <p>
        Adresse de l’hébergeur : AMAZON WEB SERVICES EMEA SARL 38 Avenue John F.
        Kennedy, L-1855 Luxembourg.
      </p>

      <h2>
        4. LE DIRECTEUR DE LA PUBLICATION EST ALEXANDRE SCHAJER – RÉSEAU E2C
        FRANCE
      </h2>
      <h2>5. DROITS DE PROPRIÉTÉ INTELLECTUELLE DU PLATEFORME </h2>
      <p>
        Tant la structure générale du Plateforme (charte graphique) que son
        contenu (textes, slogans, graphiques, images, sons, vidéos), y compris
        les logiciels, bases de données et newsletters sont la propriété du
        Réseau E2C France ou de ses prestataires et sont protégés par les lois
        et règlements applicables en matière de droits de propriété
        intellectuelle.
      </p>
      <p>
        L’utilisation de tout ou partie de la plateforme, notamment via
        téléchargement, reproduction, transmission, extraction significative de
        son contenu, représentation ou diffusion, à d’autres fins que pour
        l’usage personnel de l’utilisateur est strictement interdit. La
        violation de ces dispositions est susceptible d’engager la
        responsabilité de son auteur sur le fondement du Code de la propriété
        intellectuelle et du Code pénal en cas de contrefaçon, et par le Code
        civil s’agissant de la responsabilité civile de l’internaute.
      </p>
      <p>
        Les marques, noms de domaine ainsi que les dessins et modèles figurant
        sur le Plateforme sont la propriété exclusive du Réseau E2C France ou de
        ses prestataires. La création de liens hypertextes vers la plateforme ne
        peut être faite qu'avec l'autorisation expresse, écrite et préalable du
        Réseau E2C France, laquelle autorisation peut être révoquée à tout
        moment, sans préavis et sans motif. Les sites Internet contenant un lien
        hypertexte vers le Plateforme ne sont pas sous le contrôle du Réseau E2C
        France. Leur contenu et leur nature ne relèvent en aucun cas de la
        responsabilité du Réseau E2C France et celle-ci décline par conséquent
        toute responsabilité (notamment éditoriale) concernant l’accès et le
        contenu à ces sites.
      </p>
      <p>
        Les liens hypertextes mis en œuvre au sein de la Plateforme en direction
        d’autres sites, et d’une manière générale vers toutes ressources
        existantes sur Internet, ne sauraient engager la responsabilité du
        Réseau E2C France.
      </p>
      <p>
        Le Réseau E2C France est libre de modifier, adapter, supprimer, à tout
        moment, sans préavis et sans motif, le contenu du Plateforme.
      </p>
      <SDivClose onClick={() => close()} />
    </ModalContainer>
  );
}

export default Legal;
