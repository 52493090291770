import { useEffect, useState } from 'react';
import styled from 'styled-components';
import bgInfo from '@assets/image/background/bg-info.png';
import Title from '~/utils/components/ui/Title';
import Menu from './Menu';
import UserProgression from '~/pages/dashboard/components/UserProgression';
import UserRewards from '~/pages/dashboard/components/UserRewards';
import { IRewardsImagesUnlocked, IStatsInfo } from '~/pages/dashboard/types';
import { SSpacer } from '~/utils/styles';
import {
  getRewardsAvatarImages,
  getAvatarImages,
} from '~/pages/dashboard/utils';
import { useAuth } from '~/helpers/store/auth';
import { classic } from '~/themes';

type IProps = {
  userId: string;
  avatar: any | null;
  globalProgression: any | null;
  rewards: any | null;
};

function UserInfo({ userId, avatar, globalProgression, rewards }: IProps) {
  const [avatarImgs, setAvatarImgs] = useState<string[]>([]);
  const [rewardsImages, setRewardsImages] = useState<
    IRewardsImagesUnlocked | undefined
  >(undefined);
  //   const [dialogOpen, setOpenDialog] = useState<'stats' | undefined>(undefined);
  const [progress, setProgress] = useState<number | undefined>(undefined);
  const { payload } = useAuth();

  useEffect(() => {
    //partie progress
    if (globalProgression) {
      setProgress(Math.round(globalProgression));
    }
  }, [globalProgression]);

  useEffect(() => {
    //partie avatar
    if (avatar) {
      setRewardsImages(getRewardsAvatarImages(avatar));
      setAvatarImgs(getAvatarImages(avatar));
    }
  }, [avatar]);

  //   function handleOpenStats() {
  //     setOpenDialog('stats');
  //   }

  useEffect(() => {
    const _statsInfo: IStatsInfo = {
      uuid: userId,
      arenaCount: 0,
      hotelCount: 0,
      rewardsUnlocked: 0,
      rewardsEpic: 0,
      rewardsLegendary: 0,
      rewardsRare: 0,
      rewardsNormal: 0,
    };

    async function getData() {
      if (rewards) {
        const _rewards = rewards;
        _statsInfo.rewardsUnlocked = _rewards.total;
        _statsInfo.rewardsEpic = _rewards.epic;
        _statsInfo.rewardsLegendary = _rewards.legendary;
        _statsInfo.rewardsNormal = _rewards.normal;
        _statsInfo.rewardsRare = _rewards.rare;
      }
    }
    void getData();
  }, [rewards]);

  if (!payload) return null;

  return (
    <Container id="summary">
      <SDivBlur>
        <SDivWrapperLgScreen>
          <Menu />
          <SDivGridInfoWrapper>
            <SDivGridInfo>
              <SDivUserInfo className="info">
                <Title
                  withbackground
                  text={payload.firstName + ' ' + payload.lastName || ''}
                />

                <UserProgression progression={progress ?? 0} />

                {/* <SDivUserStats>
                  <ButtonSm textUnderline>
                    <Trans id="common.stats" />
                  </ButtonSm>
                </SDivUserStats> */}
              </SDivUserInfo>

              <SDivUserImg className="image">
                {avatarImgs.map((part) => (
                  <SImg alt="" key={part} src={part} />
                ))}
              </SDivUserImg>
              <UserRewards
                gridarea="rewards"
                rewardsCount={rewards}
                rewardsImages={rewardsImages}
              />
            </SDivGridInfo>
          </SDivGridInfoWrapper>
        </SDivWrapperLgScreen>

        <SDivWrapperSmScreen>
          <SDivUserWrapper>
            <Menu />
            <SDivUserInfo className="info">
              <Title
                withbackground
                text={payload.firstName + ' ' + payload.lastName || ''}
              />

              <p>{/* User?.  ID sur la maquette*/}</p>
            </SDivUserInfo>
            <SDivUserImg className="image">
              {avatarImgs.map((part) => (
                <SImg alt="" key={part} src={part} />
              ))}
            </SDivUserImg>
            <SSpacerSm />
          </SDivUserWrapper>

          <SDivAddUserInfo>
            <SSpacerSm />
            <UserProgression progression={progress ?? 0} />
            <SSpacerSm />
            <UserRewards
              gridarea="rewards"
              rewardsCount={rewards}
              rewardsImages={rewardsImages}
            />
            <SSpacerSm />
            {/* <SDivUserStats>
              <ButtonSm textUnderline>
                <Trans id="common.stats" />
              </ButtonSm>
            </SDivUserStats> */}
            <SSpacer />
          </SDivAddUserInfo>
        </SDivWrapperSmScreen>
      </SDivBlur>
    </Container>
  );
}

export default UserInfo;

const Container = styled.div`
  align-items: center;
  background: url(${bgInfo}) center/cover no-repeat;
  display: flex;
  flex-direction: column;
  /*min-height: 90%;*/
  justify-content: flex-start;
  position: relative;
  width: 100%;
  /*min-height: 80vh;*/
  transform-style: preserve-3d;
  @media screen and (max-width: 590px) {
    background: none;
  }
`;

const SDivBlur = styled.div`
  width: 100%;
  @media screen and (max-width: 590px) {
    backdrop-filter: blur(10px);
  }
`;
const SSpacerSm = styled.div`
  margin-top: 10px;
`;

const SDivWrapperSmScreen = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  height: 0;
  opacity: 0;
  visibility: hidden;
  width: 0;
  @media screen and (max-width: 590px) {
    height: auto;
    opacity: 1;
    visibility: visible;
    width: 100%;
  }
`;
const SDivWrapperLgScreen = styled.div`
  height: auto;
  margin: 0 auto;
  opacity: 1;
  visibility: visible;
  width: 100%;
  @media screen and (max-width: 590px) {
    height: 0;
    opacity: 0;
    visibility: hidden;
    width: 0;
  }
`;
const SDivUserWrapper = styled.div`
  background: url(${bgInfo}) center/cover no-repeat;
  width: 100%;
`;
const SDivAddUserInfo = styled.div`
  background: inherit;
  margin: 0 auto;
  width: 60%;
  @media screen and (max-width: 415px) {
    width: 75%;
  }
  @media screen and (max-width: 280px) {
    width: 85%;
  }
`;

const SDivGridInfoWrapper = styled.div`
  display: flex;
  height: 90%;
  justify-content: center;
  margin: 0 auto;
  white-space: nowrap;
  width: 100%;
  opacity: 1;
  visibility: visible;
  @media screen and (min-width: 1220px) {
    width: 70%;
  }
  @media screen and (min-width: 1220px) {
    width: 80%;
  }
  @media screen and (min-width: 992px) {
    width: 90%;
  }
`;

const SDivGridInfo = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 36% 26% 36%;
  grid-template-areas:
    'info uimage .'
    'progression uimage rewards'
    'stats uimage .';
  align-items: center;
  height: 100%;
  padding: 0 30px;
  width: 100%;

  @media screen and (max-width: 767px) {
    padding: 0 20px;
  }
  @media screen and (max-width: 590px) {
    padding: 0 10px;
    width: 220%;
    grid-template-columns: 100%;
    grid-template-rows: 0.3fr 2fr 1fr 1fr 0.3fr;
    grid-template-areas:
      'info'
      'uimage'
      'progression'
      'rewards'
      'stats';
    width: 55%;
  }
  @media screen and (max-width: 415px) {
    width: 75%;
  }
  @media screen and (max-width: 280px) {
    width: 85%;
  }
`;

const SDivUserInfo = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  & > p {
    color: ${classic.token?.colorTextSecondary};
  }
`;

const SDivUserImg = styled.div`
  position: relative;
  height: 500px;
  @media screen and (max-width: 670px) {
    height: auto;
    width: 100%;
  }
  @media screen and (max-width: 590px) {
    height: 250px;
  }
`;

const SImg = styled.img`
  height: 100%;
  position: absolute;
  object-fit: contain;
  top: 0;
  width: 100%;
`;

// const SDivUserStats = styled.div`
//   align-items: flex-start;
//   display: flex;
//   justify-content: center;
//   grid-area: stats;
// `;
