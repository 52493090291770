import styled from 'styled-components';

import iconQuestion from '@assets/image/icons/icon-question.png';

type IProps = {
  bg?: string;
};

function ImgIconLg({ bg }: IProps) {
  return (
    <Container $bg={bg}>
      {bg && <SImg alt="background" src={bg} />}
      {bg === undefined && <SImgQuestion alt="background" src={iconQuestion} />}
    </Container>
  );
}

export default ImgIconLg;

const Container = styled.div<{
  color?: 'primary';
  $bg?: string;
}>`
  align-items: center;
  background-color: ${({ $bg }) => ($bg ? '#fff' : `#ffffff1A`)};
  border: 1px solid #ffff;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  height: 140px;
  width: 140px;
  @media screen and (max-width: 992px) {
    height: 120px;
    width: 120px;
  }
  @media screen and (max-width: 767px) {
    height: 105px;
    width: 105px;
  }
  @media screen and (max-width: 667px) {
    height: 98px;
    width: 98px;
  }
`;

const SImg = styled.img`
  border-radius: 8px;
  height: 100%;
  left: 0;
  object-fit: contain;
  position: absolute;
  top: 0;
  width: 100%;
`;
const SImgQuestion = styled.img`
  border-radius: 8px;
  height: 60%;
  object-fit: contain;
  width: 60%;
`;
