import RewardContainerEpic from '@assets/image/reward/epic-reward-container.png';
import RewardContainerNormal from '@assets/image/reward/normal-reward-container.png';
import RewardContainerRare from '@assets/image/reward/rare-reward-container.png';
import RewardContainerLegendary from '@assets/image/reward/legendary-reward-container.png';
import RewardRarityLegendary from '@assets/image/reward/rarity-legendary.png';
import RewardRarityRare from '@assets/image/reward/rarity-rare.png';
import RewardRarityNormal from '@assets/image/reward/rarity-normal.png';
import RewardRarityEpic from '@assets/image/reward/rarity-epic.png';
import suAcharne from '@assets/image/icons/success/su-acharne.png';
import suAirFrais from '@assets/image/icons/success/su-air-frais.png';
import suAvatar from '@assets/image/icons/success/su-avatar.png';
import suBaroudeur from '@assets/image/icons/success/su-baroudeur.png';
import suBravo from '@assets/image/icons/success/su-bravo.png';
import suChezSoi from '@assets/image/icons/success/su-chez-soi.png';
import suCompletiste from '@assets/image/icons/success/su-completiste.png';
import suCurieux from '@assets/image/icons/success/su-curieux.png';
import suDetective from '@assets/image/icons/success/su-detective.png';
import suDodo from '@assets/image/icons/success/su-dodo.png';
import suEmotif from '@assets/image/icons/success/su-emotif.png';
import suExplorateur from '@assets/image/icons/success/su-explorateur.png';
import suFashionVictim from '@assets/image/icons/success/su-fashion-victim.png';
import suHabitue from '@assets/image/icons/success/su-habitue.png';
import suLacheRien from '@assets/image/icons/success/su-lache-rien.png';
import suMission5 from '@assets/image/icons/success/su-mission-5.png';
import suMission10 from '@assets/image/icons/success/su-mission-10.png';
import suMission20 from '@assets/image/icons/success/su-mission-20.png';
import suMission25 from '@assets/image/icons/success/su-mission-25.png';
import suMission30 from '@assets/image/icons/success/su-mission-30.png';
import suMission35 from '@assets/image/icons/success/su-mission-35.png';
import suMission40 from '@assets/image/icons/success/su-mission-40.png';
import suPapote from '@assets/image/icons/success/su-papote.png';
import suPause from '@assets/image/icons/success/su-pause.png';
import suPersiste from '@assets/image/icons/success/su-persiste.png';
import suPlaisantePas from '@assets/image/icons/success/su-plaisante-pas.png';
import suPopulaire from '@assets/image/icons/success/su-populaire.png';
import suPremiereVisite from '@assets/image/icons/success/su-premiere-visite.png';
import suSansFaute from '@assets/image/icons/success/su-sans-faute.png';
import suSape from '@assets/image/icons/success/su-sape.png';
import suSeReposer from '@assets/image/icons/success/su-se-reposer.png';
import suSociable from '@assets/image/icons/success/su-sociable.png';
import suStarMode from '@assets/image/icons/success/su-star-mode.png';
import suTop1 from '@assets/image/icons/success/su-top-1.png';
import suTop2 from '@assets/image/icons/success/su-top-2.png';
import suTop3 from '@assets/image/icons/success/su-top-3.png';
import suTop4 from '@assets/image/icons/success/su-top-4.png';
import suTop5 from '@assets/image/icons/success/su-top-5.png';
import suTop6 from '@assets/image/icons/success/su-top-6.png';
import suTop7 from '@assets/image/icons/success/su-top-7.png';
import suTop8 from '@assets/image/icons/success/su-top-8.png';
import suTop9 from '@assets/image/icons/success/su-top-9.png';
import suTop10 from '@assets/image/icons/success/su-top-10.png';
import suTourVille from '@assets/image/icons/success/su-tour-ville.png';

import { env } from '~/helpers/env';

const mediaUrl =
  env.app.env === 'local'
    ? 'https://dg4d2l3qe2ouy.cloudfront.net/assets/src/'
    : `${env.app.url}/assets/src/`;

const pathAvatarImg = 'Items/';

const pathEnd = 'Face-A.png';

const successImages = {
  S1: suAvatar,
  S2: suTop10,
  S3: suTop9,
  S4: suTop8,
  S5: suTop7,
  S6: suTop6,
  S7: suTop5,
  S8: suTop4,
  S9: suTop3,
  S10: suTop2,
  S11: suTop1,
  S12: suMission5,
  S13: suMission10,
  S14: suMission10,
  S15: suMission20,
  S16: suMission25,
  S17: suMission30,
  S18: suMission35,
  S19: suMission40,
  S20: suSape,
  S21: suDetective,
  S22: suCurieux,
  S23: suPapote,
  S24: suEmotif,
  S25: suHabitue,
  S26: suSansFaute,
  S27: suPremiereVisite,
  S28: suTourVille,
  S29: suAirFrais,
  S30: suChezSoi,
  S31: suPause,
  S32: suFashionVictim,
  S33: suStarMode,
  S34: suAcharne,
  S35: suPlaisantePas,
  S36: suLacheRien,
  S37: suPersiste,
  S38: suBravo,
  S39: suSociable,
  S40: suPopulaire,
  S41: suDodo,
  S42: suExplorateur,
  S43: suBaroudeur,
  S44: suSeReposer,
  S45: suCompletiste,
};

type Join<K extends string, P extends string> = `${K}${P}`;

type SRange =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23
  | 24
  | 25
  | 26
  | 27
  | 28
  | 29
  | 30
  | 31
  | 32
  | 33
  | 34
  | 35
  | 36
  | 37
  | 38
  | 39
  | 40
  | 41
  | 42
  | 43
  | 44
  | 45;

type SType = Join<'S', `${SRange}`>;

export function getSuccessImageByCode(code: SType): string {
  return successImages[code];
}
export function getGamerNameByType(value: number): string {
  if (value >= 75) return 'Star !';
  if (value >= 50 && value < 75) return 'Remarqué';
  if (value >= 25 && value < 50) return 'Renommé';

  return 'Inconnu';
}

export function byField(field: string) {
  return (a: any, b: any) => (a[field] > b[field] ? 1 : -1);
}

export function byFieldSNumber(field: string) {
  return (a: any, b: any) => {
    const _a = a[field].slice(1, a[field].length);

    const _b = b[field].slice(1, b[field].length);
    if (Number(_a) > Number(_b)) return 1;

    return -1;
  };
}

export function getPathSuccess(name: string): string {
  return `${mediaUrl}image/icons/success/${name}`;
}

const matchColorArr = {
  COLOR_001: 'Color1',
  COLOR_002: 'Color2',
  COLOR_003: 'Color3',
  COLOR_004: 'Color4',
  COLOR_005: 'Color5',
  COLOR_006: 'Color6',
};
const matchFaceArr = {
  FACE_001: 'Face1',
  FACE_002: 'Face2',
};

export function getAvatarImages(avatar: any): string[] {
  const imagesPaths: string[] = [];
  //@ts-expect-error
  const color = matchColorArr[avatar.color];
  //@ts-expect-error
  const face = matchFaceArr[avatar.face];

  // Push bodyPath
  const bodyPath = `${mediaUrl}${pathAvatarImg}Bodies/${color}/Body-${color}-${pathEnd}`;
  imagesPaths.push(bodyPath);

  // Push topPath
  const topPath = `${mediaUrl}${pathAvatarImg}${avatar.top.image.toLowerCase()}`;
  imagesPaths.push(topPath);

  // Push facePath
  const facePath = `${mediaUrl}${pathAvatarImg}Faces/${face}/${color}/${face}-${color}-${pathEnd}`;
  imagesPaths.push(facePath);

  // Push hatPath
  const hatPath = `${mediaUrl}${pathAvatarImg}${avatar.hat.image.toLowerCase()}`;
  imagesPaths.push(hatPath);

  // Push bottomPath
  const bottomPath = `${mediaUrl}${pathAvatarImg}${avatar.bottom.image.toLowerCase()}`;
  imagesPaths.push(bottomPath);

  return imagesPaths;
}

export function getHatImages(avatar: any): string[] {
  // Hat images
  const _hatImages = [];
  if (avatar.hat?.rarity === 'NORMAL') {
    _hatImages.push(RewardContainerNormal, RewardRarityNormal);
  }
  if (avatar.hat?.rarity === 'EPIQUE') {
    _hatImages.push(RewardContainerEpic, RewardRarityEpic);
  }
  if (avatar.hat?.rarity === 'LEGENDAIRE') {
    _hatImages.push(RewardContainerLegendary, RewardRarityLegendary);
  }
  if (avatar.hat?.rarity === 'RARE') {
    _hatImages.push(RewardContainerRare, RewardRarityRare);
  }
  const hatPath = `${mediaUrl}${pathAvatarImg}${avatar.hat?.image.toLowerCase()}`;
  _hatImages.push(hatPath);

  return _hatImages;
}
export function getBottomImages(avatar: any): string[] {
  // Bottom images
  const _bottomImages = [];
  if (avatar.bottom?.rarity === 'NORMAL') {
    _bottomImages.push(RewardContainerNormal, RewardRarityNormal);
  }
  if (avatar.bottom?.rarity === 'EPIQUE') {
    _bottomImages.push(RewardContainerEpic, RewardRarityEpic);
  }
  if (avatar.bottom?.rarity === 'LEGENDAIRE') {
    _bottomImages.push(RewardContainerLegendary, RewardRarityLegendary);
  }
  if (avatar.bottom?.rarity === 'RARE') {
    _bottomImages.push(RewardContainerRare, RewardRarityRare);
  }
  const bottomPath = `${mediaUrl}${pathAvatarImg}${avatar.bottom?.image.toLowerCase()}`;
  _bottomImages.push(bottomPath);

  return _bottomImages;
}

export function getTopImages(avatar: any): string[] {
  const _topImages = [];
  if (avatar.top?.rarity === 'NORMAL') {
    _topImages.push(RewardContainerNormal, RewardRarityNormal);
  }
  if (avatar.top?.rarity === 'EPIQUE') {
    _topImages.push(RewardContainerEpic, RewardRarityEpic);
  }
  if (avatar.top?.rarity === 'LEGENDAIRE') {
    _topImages.push(RewardContainerLegendary, RewardRarityLegendary);
  }
  if (avatar.top?.rarity === 'RARE') {
    _topImages.push(RewardContainerRare, RewardRarityRare);
  }
  const topPath = `${mediaUrl}${pathAvatarImg}${avatar.top?.image.toLowerCase()}`;
  _topImages.push(topPath);

  return _topImages;
}

export function getRewardsAvatarImages(_avatar: any): {
  topImages: string[];
  bottomImages: string[];
  hatImages: string[];
  hatRariry: string;
  topRarity: string;
  bottomRarity: string;
} {
  const _topImages = _avatar && getTopImages(_avatar);
  const _bottomImages = _avatar && getBottomImages(_avatar);
  const _hatImages = _avatar && getHatImages(_avatar);
  const _hatRarity = _avatar.hat?.rarity;
  const _topRarity = _avatar.top?.rarity;
  const _bottomRarity = _avatar.bottom?.rarity;

  return {
    topImages: _topImages || [],
    bottomImages: _bottomImages || [],
    hatImages: _hatImages || [],
    hatRariry: _hatRarity || '',
    topRarity: _topRarity || '',
    bottomRarity: _bottomRarity || '',
  };
}
