import { styled } from 'styled-components';
import { Drawer } from 'antd';
import { Trans } from '@lingui/react';
import ButtonLink from '~/utils/components/ui/ButtonLink';
import { useNavigate } from 'react-router-dom';
import ButtonSm from '~/utils/components/ui/ButtonSm';

type IProps = {
  open: boolean;
  closeDrawer: () => void;
};

function Banner({ open, closeDrawer }: IProps) {
  const navigate = useNavigate();

  return (
    <SDrawer
      placement="right"
      size="large"
      closeIcon={false}
      bodyStyle={{ background: '#0f030ce6' }}
      onClose={closeDrawer}
      open={open}
    >
      <SDivClose>
        <ButtonLink onClick={closeDrawer}>
          <Trans id="common.close" />
        </ButtonLink>
      </SDivClose>
      <SDivMenuContent>
        <a href="/game/index.html" target="_blank">
          <ButtonSm stylebold withhover onClick={closeDrawer}>
            Jouer
          </ButtonSm>
        </a>
        <a href="#summary">
          <ButtonSm stylebold withhover onClick={closeDrawer}>
            <Trans id="menu.summary" />
          </ButtonSm>
        </a>
        <a href="#success">
          <ButtonSm stylebold withhover onClick={closeDrawer}>
            <Trans id="menu.success" />
          </ButtonSm>
        </a>
        <a href="#progression">
          <ButtonSm stylebold withhover onClick={closeDrawer}>
            <Trans id="menu.progression" />
          </ButtonSm>
        </a>
        <a href="#quests">
          <ButtonSm stylebold withhover onClick={closeDrawer}>
            <Trans id="menu.quests" />
          </ButtonSm>
        </a>
        <a href="#reputation">
          <ButtonSm stylebold withhover onClick={closeDrawer}>
            <Trans id="menu.reputation" />
          </ButtonSm>
        </a>
        <a href="#logout">
          <ButtonSm
            stylebold
            withhover
            onClick={() => navigate('/logout')}
            textcolor="primary"
          >
            <Trans id="menu.logout" />
          </ButtonSm>
        </a>
      </SDivMenuContent>
    </SDrawer>
  );
}

export default Banner;

const SDivClose = styled.div`
  display: flex;
  justify-content: end;
`;

const SDivMenuContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 300px;
  width: 100%;
`;

const SDrawer = styled(Drawer)`
  &&&&&&&&&&& {
    background: none;
  }
`;
